import { Token } from "../store/token/api/types";

function mergeUniqueTokens(
    currentTokens: Token[],
    newTokens: Token[]
): Token[] {
    const existingIds = new Set(currentTokens.map((token) => token.id));
    const uniqueNewTokens = newTokens.filter((token) => !existingIds.has(token.id));
    return [...currentTokens, ...uniqueNewTokens];
}

export default mergeUniqueTokens;