import * as Sentry from "@sentry/react";
import WebApp from "@twa-dev/sdk";

export enum TelegramEventTypes {
  openTgLink = "web_app_open_link",
}

const APP_BACKGROUND = "#000000";

export function useTelegramApp() {
  const telegramApp = WebApp;

  return {
    ...telegramApp,
    finish(){if (telegramApp.isExpanded) {
      telegramApp.close();
    }},
    init() {
      try {
        telegramApp.ready();
        telegramApp.SettingsButton.show(); // Show the Settings button
        telegramApp.SettingsButton.onClick(() =>
          telegramApp.openLink("https://orbitonx.notion.site/Privacy-Policy-737d65fdce4d47ecb10916887eece68a"),
        );
        telegramApp.setBackgroundColor(APP_BACKGROUND);
        telegramApp.setHeaderColor(APP_BACKGROUND);
        telegramApp.expand();
      } catch (err) {
        const tgApiVersion = telegramApp.version || "0";
        if (Number(tgApiVersion) < 7) {
          return;
        }

        Sentry.setTag("TELEGRAM", "INIT_ERROR");
        Sentry.captureException(err);
      }
    },
    // showSettingsButton() {
    //   telegramApp.SettingsButton.show(); // Show the Settings button
    // },
    // hideSettingsButton() {
    //   telegramApp.SettingsButton.hide(); // Hide the Settings button
    // },
    // onSettingsButtonClick(callback: () => void) {
    //   telegramApp.SettingsButton.onClick(callback); // Handle click event
    // },
    // offSettingsButtonClick(callback: () => void) {
    //   telegramApp.SettingsButton.offClick(callback); // Remove click event handler
    // },
  };
}
