import { useEffect, useRef, useState, useCallback } from "react";
import * as d3 from "d3";
import { useParams } from "react-router-dom";
import { CandleData, CandlestickData } from "../../../../api/tokens/types";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks";
import { getHistoricalTokenPrice } from "../../../../api/tokens/getHistoricalTokenPrice";
import {
  changeReloadCandleDataStatus,
  reloadCandleDataSelector,
  socketConnectSelector,
} from "../../../../store/token/tokensReducer";

enum TimeGroup {
  "5m" = 5,
  "15m" = 15,
  "1H" = 60,
  "4H" = 240,
  "1D" = 1440,
}

const maxVisibleCandles = 8;
const LOCAL_STORAGE_PERIOD_KEY = "chartPeriod";

const TokenChart = () => {
  const dispatch = useAppDispatch();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const reloadCandleData = useSelector(reloadCandleDataSelector);
  const [candleData, setCandleData] = useState<CandlestickData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { tokenId } = useParams();
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const socket = useSelector(socketConnectSelector);
  const visibleData = candleData.slice(currentIndex, currentIndex + maxVisibleCandles);

  useEffect(() => {
    if (!socket) return;

    const handleTokenUpdated = (update: { tokenId: number; eventId: string }) => {
      console.log("Token updated:", update);
      socket.emit("ack", { eventId: update.eventId });
      if (update.tokenId === Number(tokenId)) {
      dispatch(changeReloadCandleDataStatus(true));
      }
    };

    socket.on("token-updated", handleTokenUpdated);

    return () => {
      socket.off("token-updated", handleTokenUpdated);
    };
  }, [socket, tokenId, dispatch]);

  const initialPeriod = () => {
    const savedPeriod = localStorage.getItem(LOCAL_STORAGE_PERIOD_KEY);
    return savedPeriod ? (parseInt(savedPeriod) as TimeGroup) : TimeGroup["15m"];
  };

  const [period, setPeriod] = useState<TimeGroup>(initialPeriod);
  const handlePeriodChange = (newPeriod: TimeGroup) => {
    if (period !== newPeriod) {
      setPeriod(newPeriod);
      localStorage.setItem(LOCAL_STORAGE_PERIOD_KEY, newPeriod.toString());
      dispatch(changeReloadCandleDataStatus(true));
      setCurrentIndex(0);
    }
  };
  const periodRef = useRef(period);
  useEffect(() => {
    periodRef.current = period;
  }, [period]);

  // Обновляем `fetchData`, чтобы принимать `currentPeriod` как аргумент
  const fetchData = useCallback(
    async (currentPeriod = period) => {
      if (!reloadCandleData) return;
      setIsLoading(true);
      try {
        const requestData: CandleData = {
          tokenId: Number(tokenId),
          CandleTimeframe: currentPeriod, // Используем переданный `currentPeriod`
          CandleSize: maxVisibleCandles,
        };
        const response = await getHistoricalTokenPrice(requestData);
        setCandleData(response);
        dispatch(changeReloadCandleDataStatus(false));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, period, tokenId, reloadCandleData],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDragStart = (e: React.TouchEvent | React.MouseEvent) => {
    const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
    setIsDragging(true);
    dragStartX.current = clientX;
  };

  const handleDragMove = (e: React.TouchEvent | React.MouseEvent) => {
    if (!isDragging || dragStartX.current === null) return;

    const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
    const dragDistance = clientX - dragStartX.current;

    if (Math.abs(dragDistance) > 50) {
      if (dragDistance > 0) {
        handleScroll("right"); // На телефоне это скролл влево
      } else {
        handleScroll("left"); // На телефоне это скролл вправо
      }
      dragStartX.current = clientX;
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    dragStartX.current = null;
  };
  const handleScroll = useCallback(
    async (direction: "left" | "right") => {
      if (isLoading) {
        console.log("Завантаження вже триває. Запит заблоковано.");
        return;
      }
      if (direction === "left") {
        if (currentIndex + maxVisibleCandles < candleData.length) {
          setCurrentIndex((prev) => prev + 1);
        } else {
          console.log("Досягнуто кінець даних.");
        }
      } else if (direction === "right") {
        if (currentIndex > 0) {
          setCurrentIndex((prev) => prev - 1);
        } else {
          const lastCandleDate = new Date(visibleData[0]?.Date).getTime() - period * 60 * 1000;
          const lastClosePrise = visibleData[0].Open;
          if (!lastCandleDate) {
            return;
          }

          const requestData: CandleData = {
            tokenId: Number(tokenId),
            CandleTimeframe: period,
            CandleSize: maxVisibleCandles,
            lastCandleDate: lastCandleDate.toString(),
            lastClosePrise: lastClosePrise,
          };

          setIsLoading(true);
          try {
            const response = await getHistoricalTokenPrice(requestData);
            if (response.length === 0) {
              console.log("Немає нових даних.");
              return;
            }

            const formattedData = response.map((item) => ({
              ...item,
              Date: new Date(item.Date),
            }));

            setCandleData((prev) => [...formattedData, ...prev]);
            setCurrentIndex((prev) => prev + formattedData.length);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }
      }
    },
    [currentIndex, isLoading, candleData, visibleData, period, tokenId],
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
  
    const calculateNextUpdateTime = () => {
      const now = new Date();
      const currentMinutes = now.getMinutes();
      const currentSeconds = now.getSeconds();
  
      const periodMinutes = period;
  
      // Рассчитываем следующий интервал
      const nextIntervalMinutes = Math.ceil((currentMinutes + currentSeconds / 60) / periodMinutes) * periodMinutes;
      const nextUpdateTime = new Date(now);
      nextUpdateTime.setMinutes(nextIntervalMinutes, 0, 0);
      const timeToNextUpdate = nextUpdateTime.getTime() - now.getTime();
  
      return timeToNextUpdate > 0 ? timeToNextUpdate : periodMinutes * 60 * 1000; 
    };
  
    const startTimer = () => {
      const timeToNextUpdate = calculateNextUpdateTime();
      console.log(`Следующее обновление через ${timeToNextUpdate / 1000} секунд`);
      timeoutId = setTimeout(() => {
        console.log("Обновление графика");
        dispatch(changeReloadCandleDataStatus(true));
        // Запускаем таймер заново
        startTimer();
      }, timeToNextUpdate);
    };
  
    startTimer();
  
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [period, dispatch]);
  
  function findMinMax(obj: CandlestickData) {
    // Отримуємо всі числові значення з об'єкта (виключаємо Date)
    const values = [obj.Open, obj.High, obj.Low, obj.Close];

    const max = Math.max(...values); // Знаходимо найбільше значення
    const min = Math.min(...values); // Знаходимо найменше значення

    return { max, min }; // Повертаємо об'єкт із максимальним та мінімальним значенням
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!reloadCandleData) return;

      setIsLoading(true);
      try {
        const requestData: CandleData = {
          tokenId: Number(tokenId),
          CandleTimeframe: period,
          CandleSize: maxVisibleCandles,
        };
        const response = await getHistoricalTokenPrice(requestData);
        setCandleData(response);
        setCurrentIndex(0); // Початковий індекс після завантаження даних
        dispatch(changeReloadCandleDataStatus(false));
      } catch (error) {
        console.error("Помилка при завантаженні даних:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, period, tokenId, reloadCandleData]);
  useEffect(() => {
    if (!svgRef.current) {
      console.warn("SVG елемент не знайдено");
      return;
    }

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const width = svgRef.current.clientWidth;
    const height = 270;
    const margin = { top: 20, right: 80, bottom: 60, left: 0 };
    const totalCandles = maxVisibleCandles; // Загальна кількість свічок для розрахунку
    const candleSpacing = 5; // Відстань між свічками

    if (isLoading) {
      svg.attr("width", width).attr("height", height);
      svg
        .append("text")
        .attr("x", "50%")
        .attr("y", "50%")
        .attr("text-anchor", "middle")
        .attr("fill", "gray")
        .text("Loading...");
      return;
    }

    if (visibleData.length === 0) {
      const width = svgRef.current.clientWidth || 0;
      const height = 270;
      svg.attr("width", width).attr("height", height);
      svg
        .append("text")
        .attr("x", "50%")
        .attr("y", "50%")
        .attr("text-anchor", "middle")
        .attr("fill", "gray")
        .text("Немає доступних даних");
      return;
    }

    // Розраховуємо ширину свічки так, щоб вони рівномірно заповнювали графік
    const candleWidth = (width - margin.left - margin.right - candleSpacing * (totalCandles - 1)) / totalCandles;

    svg.attr("width", width).attr("height", height);

    // Масштаб для x-осі
    const x = d3
      .scaleBand()
      .domain(
        Array.from({ length: totalCandles }, (_, i) => i.toString()), // Масив з індексами свічок
      )
      .range([margin.left, width - margin.right])
      .padding(0);

    // Масштаб для y-осі з обмеженням
    const y = d3
      .scaleLinear()
      .domain([
        d3.min(visibleData, (d) => {
          const { min } = findMinMax(d); // Використовуємо метод для знаходження мінімального значення
          return min;
        }) ?? 0, // Найменше значення
        d3.max(visibleData, (d) => {
          const { max } = findMinMax(d); // Використовуємо метод для знаходження максимального значення
          return max;
        }) ?? 0, // Найбільше значення
      ])
      .nice() // Додає гарні округлення для меж
      .range([height - margin.bottom - 20, margin.top]);

    const dateFormat = period === TimeGroup["1D"] ? d3.timeFormat("%d.%m") : d3.timeFormat("%H:%M");

    const xAxis = d3.axisBottom(x).tickFormat((_, i) => {
      const data = visibleData[i];
      return data ? dateFormat(new Date(data.Date)) : "";
    });

    const yAxis = d3
      .axisRight(y)
      .ticks(8) // Додати більше міток
      .tickFormat((d) => `${(+d).toFixed(5)} SOL`); // Приводим d к числу с помощью +d

    svg
      .append("g")
      .attr("transform", `translate(-2,${height - margin.bottom})`)
      .call(xAxis)
      .attr("class", "chart-scales-text")
      .selectAll("text")
      .attr("transform", "rotate(45)")
      .attr("x", 10)
      .attr("y", 5)
      .style("text-anchor", "start")
      .style("font-size", "10px");

    svg.selectAll(".domain").remove();

    const yAxisGroup = svg
      .append("g")
      .attr("transform", `translate(${width - margin.right},0)`)
      .call(yAxis);

    yAxisGroup
      .selectAll(".tick line")
      .attr("x2", -(width - margin.left - margin.right))
      .attr("stroke-opacity", 0.5)
      .attr("stroke-dasharray", "2,2");

    yAxisGroup.select(".domain").remove();

    yAxisGroup.selectAll(".tick text").attr("dx", 5).attr("dy", 2).style("text-anchor", "start");

    const g = svg.append("g").attr("stroke-linecap", "square");

    g.selectAll(".candlestick")
      .data(visibleData)
      .join("g")
      .attr("class", "candlestick")
      .attr("transform", (_, i) => {
        const xPos = margin.left + i * (candleWidth + candleSpacing);
        return `translate(${xPos},0)`;
      })
      .call((g) =>
        g
          .append("line")
          .attr("x1", candleWidth / 2)
          .attr("x2", candleWidth / 2)
          .attr("y1", (d) => y(Math.max(d.Low, 0))) // Обмеження нижньої межі
          .attr("y2", (d) => y(Math.min(d.High, d3.max(visibleData, (d) => d.High) ?? 0))) // Обмеження верхньої межі
          .attr("stroke", (d) => (d.Open > d.Close ? "#e60000" : "#008f00")),
      )
      .call((g) =>
        g
          .append("rect")
          .attr("x", 0)
          .attr("width", candleWidth)
          .attr("y", (d) => y(Math.max(d.Open, d.Close)))
          .attr("height", (d) => {
            const height = Math.abs(y(d.Open) - y(d.Close));
            return Math.max(height, 3); // Мінімальна висота свічки 3px
          })
          .attr("fill", (d) => (d.Open > d.Close ? "#e60000" : "#008f00")),
      );
  }, [visibleData, period]);

  return (
    <div className="w-full bg-black/50 rounded-lg mt-4 pt-[14px] px-[14px]">
      {/* Кнопки для вибору періоду */}
      <div className="flex justify-between px-[14px]">
        {Object.entries(TimeGroup)
          .filter(([key]) => isNaN(Number(key)))
          .map(([key, value]) => (
            <button
              key={value}
              onClick={() => handlePeriodChange(value as TimeGroup)}
              className={`px-4 py-2 rounded-lg ${period === value ? "text-white" : ""}`}
              style={{
                background:
                  period === value
                    ? "radial-gradient(405.69% 506.69% at 28.13% -143.75%, rgb(63, 193, 139) 0%, rgb(29, 117, 80) 100%)"
                    : "transparent",
              }}
            >
              {key}
            </button>
          ))}
      </div>

      {/* Область для Drag & Drop графіка */}
      <div
        className="overflow-x-auto"
        style={{ cursor: isDragging ? "grabbing" : "grab", position: "relative" }}
        onMouseDown={(e) => handleDragStart(e)}
        onMouseMove={(e) => handleDragMove(e)}
        onMouseUp={() => handleDragEnd()}
        onTouchStart={(e) => handleDragStart(e)}
        onTouchMove={(e) => handleDragMove(e)}
        onTouchEnd={() => handleDragEnd()}
      >
        <svg ref={svgRef} className="w-full h-[270px]"></svg>
      </div>
    </div>
  );
};
export default TokenChart;
