import { useSelector } from "react-redux";
import Input from "../../../ui/Input";
import {
  cryptoProfileBalanceSelector,
  isActiveWalletSelector,
} from "../../../../store/cryptoProfile/cryptoProfileReducer";
import { useEffect, useMemo, useState } from "react";
import { TradeType } from "./FilterTrade";
import {
  associatedAccountsSelector,
  currentTokenSelector,
  socketConnectSelector,
} from "../../../../store/token/tokensReducer";
import { useAppDispatch } from "../../../../store/hooks";
import { fetchCryptoProfileAction } from "../../../../store/cryptoProfile/actions/fetchCryptoProfileAction";

interface SolanaAmountFieldProps {
  solanaAmount?: string | number;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  activeTradeType: TradeType;
}

const InputAmountField = ({ handleInputChange, solanaAmount, activeTradeType }: SolanaAmountFieldProps) => {
  const [isDisableTransaction, setIsDisableTransaction] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const phantomWalletSolanaBalance = useSelector(cryptoProfileBalanceSelector);
  const associatedAccounts = useSelector(associatedAccountsSelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);
  const token = useSelector(currentTokenSelector);
  const socket = useSelector(socketConnectSelector);
  const dispatch = useAppDispatch();

  const balance = useMemo(() => {
    return activeTradeType === TradeType.BUY
      ? `Balance: ${phantomWalletSolanaBalance}`
      : `Balance: ${associatedAccounts?.tokenCount || 0}`;
  }, [phantomWalletSolanaBalance, associatedAccounts, activeTradeType]);

  const titleOfBalance = activeTradeType === TradeType.BUY ? `Sol` : `$${token?.ticker}`;

  useEffect(() => {
    if (!socket) return;

    const handleTokenUpdated = async (update: { tokenId: number; eventId: string }) => {
      socket.emit("ack", { eventId: update.eventId });
      if (update.tokenId === token?.id) {
        try {
          await dispatch(fetchCryptoProfileAction()).unwrap();
        } catch (error) {
          console.error("Error updating token data:", error);
        }
      }
    };

    socket.on("token-updated", handleTokenUpdated);
    return () => {
      socket.off("token-updated", handleTokenUpdated);
    };
  }, [socket, token]);

  useEffect(() => {
    setErrorMessage(null);
    if (!isActiveWallet) {
      setIsDisableTransaction(true);
      // setErrorMessage("Connect your wallet!");
      return;
    }

    const amount = Number(solanaAmount);

    if (amount <= 0) {
      setErrorMessage("Enter the correct value!");
      return;
    }

    if (activeTradeType === TradeType.BUY) {
      if (phantomWalletSolanaBalance && (+phantomWalletSolanaBalance <= 0 || +phantomWalletSolanaBalance < amount)) {
        setErrorMessage("Insufficient funds!");
        return;
      }
    }

    if (activeTradeType === TradeType.SELL) {
      if (!associatedAccounts) {
        setErrorMessage("You have not bought this token yet!");
        return;
      }
      if (+associatedAccounts?.tokenCount! < amount) {
        setErrorMessage("Insufficient tokens!");
        return;
      }
    }
  }, [isActiveWallet, phantomWalletSolanaBalance, solanaAmount, activeTradeType]);
  return (
    <>
      <div className="relative">
        <Input
          bgColor="rgba(0, 0, 0, 0.25)"
          value={solanaAmount}
          handleChange={handleInputChange}
          className="font-bold text-[20px] pr-[85px]"
          containerClassname="h-[56px]"
          type="text"
          error={!!errorMessage}
          readOnly={isDisableTransaction}
        />
        <div className="absolute bottom-3 right-[15px] z-10 leading-none text-right">
          <p className="uppercase font-semibold text-[16px]">{titleOfBalance}</p>
          <p className="text-xs font-medium text-[#95879e]">{balance}</p>
        </div>
      </div>
      {errorMessage && <p className="text-sm text-[#F54660] ">{errorMessage}</p>}
    </>
  );
};

export default InputAmountField;
