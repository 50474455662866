import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTransactionsError, setShownStatusError } from "../../../api/tokens/getTransactionsError";


export const fetchTransactionsError = createAsyncThunk("tokens/fetchTransactionsError", async () => {
  return getTransactionsError();
});

export const sendTransactionsErrorStatus = createAsyncThunk("tokens/sendTransactionsErrorStatus", async (transationsError: number []) => {
  return setShownStatusError(transationsError);
});

