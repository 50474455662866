import { axiosInstance } from "../axios.ts";
import { TransactionsError } from "../../store/token/api/types.ts";
import { ApiResponse } from "../types.ts";
import { apiRoutes } from "../routes.ts";
import { clearMyTransactionsError } from "../../store/token/tokensReducer.ts";

export async function getTransactionsError(): Promise<TransactionsError[]> {
  const response = await axiosInstance.get<ApiResponse<TransactionsError[]>>(apiRoutes.transactionsError.getMyTransactionsError);
  return response.data.data;
}

export async function setShownStatusError(errorsId: number[]): Promise<ApiResponse<object>> {
    clearMyTransactionsError()
    
    const response = await axiosInstance.post<ApiResponse<object>>(
      apiRoutes.transactionsError.sentErrorShown, 
      { errorsId }
    );
    clearMyTransactionsError()
    return response.data;
  }
