import { io, Socket } from "socket.io-client";
import { getAccessToken } from "./getAccessToken";

export async function disconnectSocket(socket: Socket | null) {
  socket?.disconnect();
}

export async function connectToSocket() {
  const token = await getAccessToken();
  if (!token) {
    console.error("Unable to connect to WebSocket: No token available");
    return null;
  }

  
  const socket: Socket = io(`wss://${import.meta.env.VITE_SOCKET_URL}/pampadurwss/`, {
    path: "/pampadurwss/",
    auth: {
      authorization: `Bearer ${token}`,
    },
    transports: ["websocket", "polling"],
  });

  socket.on("connect", () => {
    socket.emit("join-room", {"roomName":"token-trade-room"});
    console.log("Socket connected to token-trade-room:", socket.id);
  });

  socket.on("token-update", (data) => {
    console.log("Обновление токена получено:", data);
  });

  socket.on("socket-is-working", (data) => {
    console.log("Сообщение проверки сокета:", data.message);
  });

  socket.on("disconnect", () => {
    console.log("Socket disconnected");
  });

  return socket;
}
