// import Button from "../../ui/Button";
import Title from "../../ui/Title";

// import plusIcon from "../../../assets/icons/plus.svg";
import { useCallback, useEffect, useState } from "react";
import SearchBar from "../../ui/SearchBar";
import TokensFilter, { FilterKey } from "./TokensFilter";
import { useSelector } from "react-redux";
import {
  clearFilteredTokens,
  clearMyTokens,
  filteredTokensSelector,
  totalFilteredTokensSelector,
} from "../../../store/token/tokensReducer";
import TokenItem from "../Token/TokenItem/TokenItem";
import { useAppDispatch } from "../../../store/hooks";
import { fetchHotTokensListAction } from "../../../store/token/actions/fetchHotTokensListAction";
import { fetchAllNewTokensListAction } from "../../../store/token/actions/fetchAllNewTokensListAction";
import { fetchTopCapTokensListAction } from "../../../store/token/actions/fetchTopCapTokensListAction";
import { fetchMyTokensListAction } from "../../../store/token/actions/fetchMyTokensListAction";
// import { useNavigate } from "react-router-dom";
import { fetchSearchTokensListAction } from "../../../store/token/actions/fetchSearchTokensListAction";
import { debounce } from "lodash-es";
// import { isActiveWalletSelector } from "../../../store/cryptoProfile/cryptoProfileReducer";
// import { useToastNotifications } from "../../../hooks/useToastNotifications";
// import { ToastNotificationType } from "../../../store/toastNotifications/toastNotificationsReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import { PageParams, SearchTokensParams } from "../../../api/tokens/types";
import { motion } from "framer-motion";

const TokenList = () => {
  const [searchToken, setSearchToken] = useState("");
  const [activeTab, setActiveTab] = useState<FilterKey>(FilterKey.Hot);
  const [page, setPage] = useState(1);

  const pageSize = 5;

  const filteredTokens = useSelector(filteredTokensSelector);
  const totalTokens = useSelector(totalFilteredTokensSelector);
  // const isActiveWallet = useSelector(isActiveWalletSelector);
  const dispatch = useAppDispatch();

  const hasMore = totalTokens > filteredTokens.length;

  // const navigate = useNavigate();

  // const { showNotification } = useToastNotifications();

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleSelectTab = (tab: FilterKey) => {
    if (tab === activeTab) return;
    dispatch(clearFilteredTokens());
    setActiveTab(tab);
    setSearchToken("");
    setPage(1);
  };

  const fetchTokenList = (tab: FilterKey, page: number, pageSize: number) => {
    const params: PageParams = {
      page: page,
      pageSize: pageSize,
    };
    switch (tab) {
      case FilterKey.Hot: {
        dispatch(fetchHotTokensListAction(params));
        break;
      }
      case FilterKey.New: {
        dispatch(fetchAllNewTokensListAction(params));
        break;
      }
      case FilterKey.TopMCAP: {
        dispatch(fetchTopCapTokensListAction(params));
        break;
      }
      case FilterKey.MyTokens: {
        dispatch(fetchMyTokensListAction(params));
        break;
      }
    }
  };

  useEffect(() => {
    if (page == 1 && searchToken.length == 0) {
      dispatch(clearFilteredTokens());
    }
    if (searchToken.length == 0) {
      fetchTokenList(activeTab, page, pageSize);
    } else {
      if (page !== 0) {
        const params: SearchTokensParams = {
          page: page,
          pageSize: pageSize,
          term: searchToken,
        };
        dispatch(fetchSearchTokensListAction(params));
      }
    }
  }, [page, activeTab]);

  useEffect(() => {
    return () => {
      Promise.all([dispatch(clearMyTokens()), dispatch(clearFilteredTokens())]);
    };
  }, []);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      dispatch(clearFilteredTokens());
      const params: SearchTokensParams = {
        page: 0,
        pageSize: pageSize,
        term: value,
      };
      dispatch(fetchSearchTokensListAction(params));
      setPage(0);
    }, 300),
    [],
  );

  const handleSearch = (value: string) => {
    setSearchToken(value);
    if (value.length !== 0) {
      debouncedSearch(value);
    } else {
      if (page === 1) {
        dispatch(clearFilteredTokens());
        fetchTokenList(activeTab, page, pageSize);
      } else {
        setPage(1);
      }
    }
  };

  // const handleCreateNewToken = () => {
  //   if (isActiveWallet) {
  //     navigate("/pumpadur/create-token");
  //   } else {
  //     showNotification({
  //       title: "Connect your wallet!",
  //       type: ToastNotificationType.Error,
  //     });
  //   }
  // };

  return (
    <div className="pt-[21px] pb-[100px]">
      <div className="">
        {/* <div className="sticky top-4 z-10"> */}
        <div className="flex justify-between items-center ">
          <Title text="Tokens" style="mb-0" />
          <div 
          // onClick={handleCreateNewToken} 
          className="relative w-[170px] h-[42px] flex justify-center">
            {/* <Button
              text="Create new coin"
              style="linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)"
              className="rounded-[32px] w-[162px] text-[12px] h-[38px] px-3 z-40 !py-3"
              classNameIcon="w-[14px] h-[14px] mr-1"
              icon={plusIcon}
            /> */}
            <div
              className="absolute top-1 w-full  h-[38px] rounded-2xl z-10"
              style={{
                backgroundImage: " linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)",
                filter: "blur(40px)",
              }}
            ></div>
          </div>
        </div>
        <div className="pt-[10px]">
          <SearchBar value={searchToken} setValue={handleSearch} />
        </div>
        <TokensFilter onSelectTab={handleSelectTab} activeTab={activeTab} />
      </div>{" "}
      {filteredTokens.length > 0 ? (
        <InfiniteScroll
          dataLength={filteredTokens.length}
          next={() => setTimeout(() => loadMore(), 300)}
          className="relative flex flex-col gap-2 pt-3 !overflow-hidden"
          hasMore={hasMore}
          loader={<span></span>}
        >
          {filteredTokens?.map((token) => (
            <motion.div
              key={token.id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <TokenItem token={token} />
            </motion.div>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="h-screen"></div>
      )}
    </div>
  );
};

export default TokenList;
