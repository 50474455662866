import { useSelector } from "react-redux";
import ProgressLine from "../../../Common/ProgressLine";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import { moneyFormat } from "../../../../helpers/moneyFormat";

const TokenMetrics = () => {
  const token = useSelector(currentTokenSelector);
  const { progress } = token!;

  // const goal = ((+marcetCap * 100) / +progress) | 0;
  const goal = 25000;

  return (
    <div className="pt-[3.5vh] leading-none">
      <div className="flex justify-between  font-bold pb-[7px]">
        <p className="text-medGrey">Progress</p>
        <p className="font-bold">{progress}% to Raydium</p>
      </div>
      <ProgressLine progress={+progress} styles="bg-white/25" />
      <p className="text-[12px] text-center pt-[11px] leading-4 text-[#a98dbd] font-medium">
        When the market cap reaches ${moneyFormat(+goal)}, all the liquidity from the bonding curve will be deposited
        into Raydium and burned. Progression increases as the price goes up.
      </p>
    </div>
  );
};

export default TokenMetrics;
