import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import UserSummary, { WalletViewMode } from "../../components/Pumpadur/Profile/UserSummary";
import CreatedTokensList from "../../components/Pumpadur/Token/TokenList/MyCreatedTokensList";
// import NftList from "../../components/Pumpadur/Profile/Nft/NftList";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../store/account/account";
import ReferralSystem from "../../components/Common/ReferralSystem";
import TransactionListWithInfiniteScroll from "../../components/Pumpadur/Transaction/TransactionListWithInfiniteScroll";
import Title from "../../components/ui/Title";
import { getMyTransactions } from "../../api/transactions/getMyTransactions";
// import { getMyNfts } from "../../api/wallet/getMyNfts";
// import { Nft } from "../../store/cryptoProfile/types";

const ProfilePage = () => {
  const userId = useSelector(userIdSelector);

  const dispatch = useAppDispatch();

  // const [myNfts, setMyNfts] = useState<Nft[] | null>(null);

  const startPram = `pumpadur/user-profile/${userId}`;

  // const fetchMyNftsAction = async () => {
  //   const result = await getMyNfts();

  //   setMyNfts(result);
  // };

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    // fetchMyNftsAction();
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="relative pb-[20px]">
      <UserSummary walletViewMode={WalletViewMode.MY_WALLET} abilitytoRedirectToWalletManagement={true} />

      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Your Referral link</h2>
        <ReferralSystem
          text="Share"
          startParam={startPram}
          background="linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)"
        />
      </div>
      {/* {myNfts && <NftList list={myNfts} />} */}
      <CreatedTokensList />
      <div className="pt-8">
        <Title text="Last Transactions" />
        <TransactionListWithInfiniteScroll fetchFunction={getMyTransactions} />
      </div>
    </div>
  );
};

export default ProfilePage;
