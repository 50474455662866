import { CommunityNotesData } from "../api/communityNotes/types";

export const mergeCommunityNotes = (
    currentData: CommunityNotesData,
    newData: CommunityNotesData
  ): CommunityNotesData => {
    const existingIds = new Set(currentData.items.map((note) => note.id));
  
    const filteredNewItems = newData.items.filter((note) => !existingIds.has(note.id));
  
    return {
      items: [...currentData.items, ...filteredNewItems],
      total: currentData.total + filteredNewItems.length,
    };
  };
  