import { useEffect, useState } from "react";
import LastTransaction from "../../components/Pumpadur/Main/LastTransaction";
import TokenList from "../../components/Pumpadur/Main/TokenList";
import TopTokenBanner from "../../components/Pumpadur/Main/TopTokenBanner";
import UserProfileHeader from "../../components/Pumpadur/UserProfileHeader";
import { Token, TransactionsError } from "../../store/token/api/types";
import { getTopCapToken } from "../../api/tokens/getTopCapToken";
// import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import { fetchCryptoProfileAction } from "../../store/cryptoProfile/actions/fetchCryptoProfileAction";
import { useToastNotifications } from "../../hooks/useToastNotifications";
import { fetchTransactionsError, sendTransactionsErrorStatus } from "../../store/token/actions/fetchTransactionsError";
import { ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer";

const MainPage = () => {
  const [capTopToken, setCapTopToken] = useState<Token | null>(null);
  // const navigate = useNavigate();
  // const userRole = useAppSelector((state) => state.account.roleId);
  const transactionsError = useAppSelector((state) => state.tokens.transactionsError);
  const dispatch = useAppDispatch();
  const { showNotification } = useToastNotifications();

  const showErrorsInSequence = (errors: TransactionsError[]) => {
    const errorIds = errors.map((error) => error.id);
    let currentIndex = 0;

    const showNextError = () => {
      if (currentIndex < errors.length) {
        const error = errors[currentIndex];
        const formattedDate = new Date(error.createdAt).toLocaleString();
        showNotification({
          title: "Transaction Failed",
          htmlContent: `
      Your attempt to ${error.status} ${error.tokenAmount} tokens for $${error.price} 
      on ${formattedDate} failed. Error ID: ${error.id}. Please try again later.
    `,
          type: ToastNotificationType.Error,
          timeoutMs: 5000,
        });

        currentIndex++;
        setTimeout(() => {
          showNextError();
        }, 5500);
      } else {
        dispatch(sendTransactionsErrorStatus(errorIds));
      }
    };

    showNextError();
  };

  // useEffect(() => {
  //   if (userRole !== 6) {
  //     navigate("/pumpadur");
  //   }
  // }, [userRole, navigate]);

  useEffect(() => {
    if (transactionsError && transactionsError.length > 0) {
      showErrorsInSequence(transactionsError);
    }
  }, [transactionsError]);

  useEffect(() => {
    dispatch(fetchCryptoProfileAction());
    dispatch(changeStatusBottomMenu(true));
    dispatch(fetchTransactionsError());
    getTopCapToken().then((data) => {
      setCapTopToken(data);
    });
  }, []);

  return (
    <div>
      <UserProfileHeader />
      <div className="pt-4">
        <LastTransaction />
      </div>
      <div className="pt-2">
        <TopTokenBanner token={capTopToken} />
      </div>
      <TokenList />
    </div>
  );
};

export default MainPage;
