import React, { useCallback, useEffect, useRef, useState } from "react";

import { TadsWidget } from "react-tads-widget";

import balanceIcon from "../../assets/icons/balance.svg";
import chevronRightIcon from "../../assets/icons/chevron-right.svg";
import { moneyFormat } from "../../helpers/moneyFormat";
import { fetchRewardForTaskAdsAction } from "../../store/account/actions/fetchRewardForTaskAdsAction";
import { useToastNotifications } from "../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer";
import { useAppDispatch } from "../../store/hooks";

const TadsWidgetTask = React.memo(
  ({ pageName, blockId, reward = true }: { pageName: string; blockId: string; reward: boolean }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [key, setKey] = useState(0);

    const { showNotification } = useToastNotifications();

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!containerRef.current) return;
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            const addedNodes = Array.from(mutation.addedNodes) as HTMLElement[];
            addedNodes.forEach((node) => {
              if (node && node.classList && node.classList.contains("tads")) {
                insertHTMLToWrapper();
              }
            });
          }
        }
      });

      observer.observe(containerRef.current, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
        const existingScript = document.querySelector(`script[src="https://w.tads.me/widget.js"]`);
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      };
    }, [key]);

    const insertHTMLToWrapper = () => {
      if (containerRef.current) {
        const tads = containerRef.current.querySelectorAll(".tads");
        if (tads) {
          tads.forEach((wrapper) => {
            const htmlChevronRight = `    
                    <span class="absolute right-3 block mr-2">
                      <img src=${chevronRightIcon} alt="Right arrow" />
                    </span>`;
            wrapper.insertAdjacentHTML("beforeend", htmlChevronRight);
            if (reward) {
              const tadsTextWrapper = wrapper.querySelector(".tads-text-wrapper");

              if (tadsTextWrapper && !wrapper.querySelector(".balance-info")) {
                const htmlToInsert = `
                  <strong class="balance-info flex mt-0.5 text-orangeLight items-center gap-x-[0.286em] text-[14px] pr-4">
                    <span class="size-4">
                      <img class="size-full object-cover" loading="lazy" src="${balanceIcon}" alt="currency" />
                    </span>
                    ${moneyFormat(10)}
                  </strong>
                `;

                tadsTextWrapper.insertAdjacentHTML("beforeend", htmlToInsert);
              }
            }
          });
        }
        // }
      }
    };

    // const ADSGRAM_BLOCK_ID = import.meta.env.VITE_TADSWIDGET_BLOCK_ID;

    const rewardUserByShow = useCallback(() => {
      console.log("rewardUserByShow");
    }, []);

    const rewardUserByClick = useCallback(() => {
      console.log("rewardUserByClick");

      dispatch(fetchRewardForTaskAdsAction(pageName));

      showNotification({
        title: "You've been awarded 10 $RCT",
        type: ToastNotificationType.Success,
        timeoutMs: 2000,
      });

      setKey((k: number) => k + 1);
    }, []);

    console.log("reward", reward);

    return (
      <div ref={containerRef} key={key}>
        <TadsWidget
          id={blockId}
          debug={false}
          onClickReward={reward ? rewardUserByClick : null}
          onShowReward={reward ? rewardUserByShow : null}
        />
      </div>
    );
  },
);

export default TadsWidgetTask;
