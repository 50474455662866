import { useSelector } from "react-redux";
import { getTimeDifference } from "../../../../../helpers/getTimeDifference";
import { TokenInfo, Transaction, TransactionsStatus } from "../../../../../store/token/api/types";
import { forStatusTransaction } from "../../../Profile/Transaction/TransactionItem";
import { currentTokenSelector, socketConnectSelector } from "../../../../../store/token/tokensReducer";

import buyIcon from "../../../../../assets/icons/arrow-buy.svg";
import sellIcon from "../../../../../assets/icons/arrow-sell.svg";
import TokenImage from "../../TokenImage";
import { truncateDecimal } from "../../../../../helpers/truncateDecimal";
import { ResponseTransactionssListData, TransactionsByTokenParams } from "../../../../../api/transactions/types";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getTransactionsByToken } from "../../../../../api/transactions/getTransactionsByToken";
import { useParams } from "react-router-dom";

const PAGE_SIZE = 20;

const TransactionList = () => {
  const [transactions, setTransactions] = useState<ResponseTransactionssListData | null>(null);
  const [page, setPage] = useState(1);
  const [isSocketUpdate, setIsSocketUpdate] = useState(false);

  const { tokenId } = useParams();
  const token = useSelector(currentTokenSelector);
  const socket = useSelector(socketConnectSelector);
  const hasMore = transactions ? transactions?.total > transactions?.items.length : false;

  const fetchTransactions = async (reset = false) => {
    try {
        const params: TransactionsByTokenParams = {
            tokenId: +tokenId!,
            page: reset ? 1 : page,
            pageSize: PAGE_SIZE,
        };
        const result = await getTransactionsByToken(params);
        if (result) {
            setTransactions((prev) => {
                if (reset || !prev) {
                    setPage(1);
                    return result;
                }
                const mergedItems = [...prev.items, ...result.items];

                const uniqueItems = Array.from(
                    new Map(mergedItems.map((transaction) => [transaction.id, transaction]))
                        .values()
                );

                return {
                    ...prev,
                    items: uniqueItems,
                };
            });
        }
    } catch (error) {
        setIsSocketUpdate(false);
        console.error("Failed to fetch transactions:", error);
    }
};
  useEffect(() => {
    if (!socket) return;

    const handleTokenUpdated = async (update: { tokenId: number; eventId: string }) => {
      console.log("Token updated:", update);
      socket.emit("ack", { eventId: update.eventId });
      if (update.tokenId === Number(tokenId)) {
        setIsSocketUpdate(true);
        await fetchTransactions(true);
      }
    };
    socket.on("token-updated", handleTokenUpdated);
    return () => {
      socket.off("token-updated", handleTokenUpdated);
    };
  }, [socket, tokenId]);

  useEffect(() => {
    if (isSocketUpdate) {
      setIsSocketUpdate(false);
      return;
    } else {
      fetchTransactions();
      return;
    }
  }, [page]);

  useEffect(() => {
    fetchTransactions(true);
  }, [tokenId]);

  return (
    <>
      {transactions && transactions.items.length > 0 && (
        <div className="pt-[33px]">
          <h3 className="text-[20px] font-bold text-center">Latest transactions</h3>
          <div className="pt-4">
            <InfiniteScroll
              dataLength={transactions.items.length}
              next={() => setPage((prev) => prev + 1)}
              className="relative flex flex-col gap-2 pt-3"
              hasMore={hasMore}
              loader={<span>Loading...</span>}
            >
              {transactions.items.map((transaction) => (
                <TransactionItem key={transaction.id} transaction={transaction} token={token!} />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
};

const TransactionItem = ({ transaction, token }: { transaction: Transaction; token: TokenInfo }) => {
  if (!transaction || !token || !transaction.user) {
    return null;
  }

  const { coinQuantity = 0, createdAt = "", status, user, tokenAmount = 0 } = transaction;
  const { first_name = "Unknown" } = user;
  const { ticker = "N/A", logo = "" } = token;

  const colorForStatusTransaction = forStatusTransaction(status);

  return (
    <div className="flex justify-between items-center font-semibold leading-none  text-[12px] border-solid border-b-[2px] border-white/10 py-[15px]">
      <div className="flex gap-3 justify-between items-center">
        <div className="rounded-2xl bg-white size-6">
          <TokenImage imageSrc={logo} classesName="size-full object-cover rounded-full" />
        </div>
        <div className="flex flex-col gap-[7px]">
          <p>@{first_name}</p>
          <div className="flex gap-2">
            <p className={`font-bold ${colorForStatusTransaction} flex items-center gap-[3px]`}>
              <img src={`${status === TransactionsStatus.BUY ? buyIcon : sellIcon}`} className="inline-block size-2" />{" "}
              {status}
            </p>
            <p className="font-medium text-paleGrey">{getTimeDifference(createdAt)}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[7px] text-right">
        <p>{truncateDecimal(+coinQuantity, 7)} SOL</p>
        <div className=" text-paleGrey">
          <span>{truncateDecimal(+tokenAmount || 0, 7)} </span>
          <span className="uppercase">${ticker}</span>
        </div>
      </div>
    </div>
  );
};

export default TransactionList;
