import { useSelector } from "react-redux";
import { moneyFormat } from "../../../../helpers/moneyFormat";
// import { TradeType } from "./FilterTrade";
import { currentTokenSelector, socketConnectSelector } from "../../../../store/token/tokensReducer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchTokenInfoAction } from "../../../../store/token/actions/fetchTokenInfoAction";
import { useAppDispatch } from "../../../../store/hooks";

const TradeInfo = () =>
  // { activeTradeType }: { activeTradeType: TradeType }
  {
    const token = useSelector(currentTokenSelector);
    const { tokenId } = useParams();
    const socket = useSelector(socketConnectSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!socket) return;

      const handleTokenUpdated = (update: { tokenId: number; eventId: string }) => {
        console.log("Token updated:", update);
        socket.emit("ack", { eventId: update.eventId });
        if (update.tokenId === Number(tokenId)) {
        dispatch(fetchTokenInfoAction(tokenId!));}
      };

      socket.on("token-updated", handleTokenUpdated);

      return () => {
        socket.off("token-updated", handleTokenUpdated);
      };
    }, [socket, tokenId, dispatch]);

    return (
      <div>
        <div className="flex gap-2 w-full pb-2">
          <Block
            value={`${moneyFormat(1000000000)} $${token?.ticker || null}`}
            text="Max Supply"
            className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
          />
          <Block
            value={`${moneyFormat(Number(token?.totalSupply) || 0)} $${token?.ticker || null}`}
            text="Available to buy"
            className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
          />
        </div>
        <div className="flex gap-2 w-full">
          <Block
            value={` $ ${moneyFormat(+(token?.marcetCap || 0))}`}
            text="Market Cap"
            className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
          />
          <Block
            value={`${+(token?.liquidity || 0)} SOL`}
            text="Liquidity"
            className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
          />
        </div>
      </div>
    );
  };

const Block = ({ text, value, className }: { text: string; value: string | number; className?: string }) => {
  return (
    <div className={`flex flex-col gap-[14px]    leading-none  ${className}`}>
      <p className="font-semibold text-lavenderGray">{text}</p>
      <p style={{ lineHeight: "1.625" }} className="font-bold">
        {value}
      </p>
    </div>
  );
};

export default TradeInfo;
