import {
  CurrentCountTokensAndPrice,
  CurrentCountTokensForSell,
  Note,
  Token,
  TokenData,
  TokenPriceData,
  Transaction,
  TransactionsError,
} from "./api/types";
import { fetchMyTokensListAction } from "./actions/fetchMyTokensListAction";
import { RootState } from "../store";
import { fetchMyTransactionListAction } from "./actions/fetchMyTransactionListAction";
import { fetchHotTokensListAction } from "./actions/fetchHotTokensListAction";
import { fetchAllNewTokensListAction } from "./actions/fetchAllNewTokensListAction";
import { fetchTopCapTokensListAction } from "./actions/fetchTopCapTokensListAction";
import { sendTokenAction } from "./actions/sendTokenAction";
import { fetchSearchTokensListAction } from "./actions/fetchSearchTokensListAction";
import { fetchIsCurrentTokenExist, fetchTokenInfoAction, fetchTokenProgress } from "./actions/fetchTokenInfoAction";
import { fetchNotesForTokenAction } from "./actions/fetchNotesForTokenAction";
import { createNoteAction } from "./actions/createNoteAction";
import { patchNoteForTokenAction } from "./actions/patchNoteForTokenAction";
import { fetchLastNoteForTokenAction } from "./actions/fetchLastNoteForTokenAction";
import { fetchTokenPriceDataAction } from "./actions/fetchTokenPriceDataAction";
import { fetchCountTokensAndPriceAction } from "./actions/fetchCountTokensAndPriceAction";
import { fetchCountTokensForSellAction } from "./actions/fetchCountTokensForSellAction";
import { patchLastNoteForTokenAction } from "./actions/patchLastNoteForTokenAction";
import { ResponseTokensListData } from "../../api/tokens/types";
import { CommunityNotesData } from "../../api/communityNotes/types";
import { Socket } from "socket.io-client";
import { createSlice, PayloadAction, Draft } from "@reduxjs/toolkit";
import { fetchTransactionsError } from "./actions/fetchTransactionsError";
import mergeUniqueTokens from "../../helpers/compareTokensList";
import { mergeCommunityNotes } from "../../helpers/compareNotesList";

export interface TokensState {
  myTokens: ResponseTokensListData | null;
  myTokensPage: number;
  filteredTokens: Token[];
  totalFilteredTokens: number;
  communityNotes: CommunityNotesData | null;
  lastNote: Note | null;
  myTransaction: Transaction[];
  pending: boolean;
  error: string | null;
  currentToken: TokenData | null;
  isCurrentTokenExist: boolean;
  currentTokenPriceData: TokenPriceData | null;
  currentCountTokensAndPrice: CurrentCountTokensAndPrice | null;
  currentCountTokensForSell: CurrentCountTokensForSell | null;
  isReloadCandleData: boolean ,
  progress: string,
  marcetCap: string,
  tokenSocket: Draft<Socket> | null;
  isFromDeepLink: boolean;
  transactionsError: TransactionsError[]
}

const initialState: TokensState = {
  myTokens: null,
  myTokensPage: 1,
  filteredTokens: [],
  totalFilteredTokens: 0,
  communityNotes: null,
  myTransaction: [],
  lastNote: null,
  pending: false,
  error: null,
  currentToken: null,
  currentTokenPriceData: null,
  currentCountTokensAndPrice: null,
  currentCountTokensForSell: null,
  isReloadCandleData: true,
  progress: '',
  marcetCap:'',
  isCurrentTokenExist: false,
  tokenSocket: null,
  isFromDeepLink: false,
  transactionsError: []
};

const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    clearFilteredTokens(state) {
      state.filteredTokens = [];
    },
    clearMyTokens(state) {
      state.myTokens = null;
      state.myTokensPage = 1;
    },
    clearMyTransactionsError(state) {
      state.transactionsError = []
    },
    clearCommunityNotes(state) {
      state.communityNotes = null;
    },
    clearCurrentToken(state) {
      state.currentToken = null;
      state.isCurrentTokenExist = false;
    },
    nextPage(state) {
      state.myTokensPage = state.myTokensPage + 1;
    },
    clearPage(state) {
      state.myTokensPage = state.myTokensPage + 1;
    },
    changeReloadCandleDataStatus(state, action) { 
      state.isReloadCandleData = action.payload;
    },
    setSocket(state, action: PayloadAction<Socket | null>) {
      state.tokenSocket = action.payload as Draft<Socket> | null;
    },
    setIsFromDeepLinkStatus(state, action) {
      state.isFromDeepLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchMyTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.myTokens = state.myTokens
          ? {
              ...state.myTokens,
              items: [...state.myTokens.items, ...action.payload.items],
              total: action.payload.total,
            }
          : action.payload;
          state.filteredTokens = mergeUniqueTokens(state.filteredTokens, action.payload.items);
        state.totalFilteredTokens = action.payload.total;
      })
      .addCase(fetchMyTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });

    //MY TRANSACTION
    builder
      .addCase(fetchMyTransactionListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchMyTransactionListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.myTransaction = action.payload.items;
      })
      .addCase(fetchMyTransactionListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });

    //HOT TOKENS
    builder
      .addCase(fetchHotTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchHotTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.filteredTokens = mergeUniqueTokens(state.filteredTokens, action.payload.items);
        state.totalFilteredTokens = action.payload.total;
      })
      .addCase(fetchHotTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //NEW TOKENS
    builder
      .addCase(fetchAllNewTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchAllNewTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
    state.filteredTokens = mergeUniqueTokens(state.filteredTokens, action.payload.items);
    state.totalFilteredTokens = action.payload.total;
      })
      .addCase(fetchAllNewTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //TOP TOKENS
    builder
      .addCase(fetchTopCapTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchTopCapTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.filteredTokens = mergeUniqueTokens(state.filteredTokens, action.payload.items);
        state.totalFilteredTokens = action.payload.total;
      })
      .addCase(fetchTopCapTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //CREATE TOKEN
    builder
      .addCase(sendTokenAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(sendTokenAction.fulfilled, (state) => {
        state.pending = false;
        state.error = null;
      })
      .addCase(sendTokenAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //SEARCH TOKENS
    builder
      .addCase(fetchSearchTokensListAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchSearchTokensListAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.filteredTokens = mergeUniqueTokens(state.filteredTokens, action.payload.items);
        state.totalFilteredTokens = action.payload.total;
      })
      .addCase(fetchSearchTokensListAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //IS CURRENT TOKEN EXIST
    builder
    .addCase(fetchIsCurrentTokenExist.pending, (state) => {
      state.pending = true;
      state.error = null;
    })
    .addCase(fetchIsCurrentTokenExist.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.isCurrentTokenExist = action.payload;
    })
    .addCase(fetchIsCurrentTokenExist.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message || "Unknown error";
    });

    //CURRENT TOKEN
    builder
      .addCase(fetchTokenInfoAction.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchTokenInfoAction.fulfilled, (state, action) => {
        state.error = null;
        state.currentToken = action.payload;
        state.currentTokenPriceData = action.payload.tokenPriceData;
      })
      .addCase(fetchTokenInfoAction.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
      });
    //CURRENT COUNT TOKENS AND PRICE
    builder.addCase(fetchCountTokensAndPriceAction.fulfilled, (state, action) => {
      state.error = null;
      state.currentCountTokensAndPrice = action.payload;
    });
    //CURRENT COUNT TOKENS FOR SELL
    builder.addCase(fetchCountTokensForSellAction.fulfilled, (state, action) => {
      state.error = null;
      state.currentCountTokensForSell = action.payload;
    });
    //CURRENT TOKEN PRICE DATA
    builder.addCase(fetchTokenPriceDataAction.fulfilled, (state, action) => {
      state.currentTokenPriceData = action.payload.tokenPriceData;
    });
    //GET TRANSACTIONS ERROR
    builder.addCase(fetchTransactionsError.fulfilled, (state, action) => {
      state.transactionsError = action.payload;
    });
    //NOTES FOR CURRENT TOKEN
    builder
      .addCase(fetchNotesForTokenAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchNotesForTokenAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        if (state.communityNotes) {
          state.communityNotes = mergeCommunityNotes(state.communityNotes, action.payload);
        } else {
          state.communityNotes = action.payload;
        }
      })
      .addCase(fetchNotesForTokenAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //CREATE NEW NOTE FOR CURRENT TOKEN
    builder
      .addCase(createNoteAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(createNoteAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        if (state.communityNotes && state.communityNotes?.items?.length > 0) {
          state.communityNotes?.items.unshift(action.payload);
        } else {
          state.communityNotes = { items: [action.payload], total: 1 };
        }
      })
      .addCase(createNoteAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //LAST NOTE FOR CURRENT TOKEN
    builder
      .addCase(fetchLastNoteForTokenAction.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchLastNoteForTokenAction.fulfilled, (state, action) => {
        state.error = null;
        state.lastNote = action.payload;
      })
      .addCase(fetchLastNoteForTokenAction.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
      });
      //GET TOKEN PROGRESS
    builder
    .addCase(fetchTokenProgress.pending, (state) => {
      state.error = null;
    })
    .addCase(fetchTokenProgress.fulfilled, (state, action) => {
      state.error = null;
      state.progress = action.payload.progress;
      state.marcetCap = action.payload.marcetCap;
    })
    .addCase(fetchTokenProgress.rejected, (state, action) => {
      state.error = action.error.message || "Unknown error";
    });
    //CHANGE NOTE
    builder
      .addCase(patchNoteForTokenAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(patchNoteForTokenAction.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        const updatedNote = action.payload;
        if (state.communityNotes?.items) {
          state.communityNotes.items = state.communityNotes.items.map((note) =>
            note.id === updatedNote.id ? { ...note, like: updatedNote.like, disLike: updatedNote.disLike } : note,
          );
        }
      })
      .addCase(patchNoteForTokenAction.rejected, (state, action) => {
        state.pending = false;
        state.error = action.error.message || "Unknown error";
      });
    //CHANGE LAST NOTE ON TOKEN PAGE
    builder.addCase(patchLastNoteForTokenAction.fulfilled, (state, action) => {
      const updatedNote = action.payload;

      state.lastNote = state.lastNote
        ? {
            ...state.lastNote,
            like: updatedNote.like,
            disLike: updatedNote.disLike,
          }
        : updatedNote;
    });
  },
});

export const { clearFilteredTokens, nextPage, clearMyTokens, clearPage, clearCommunityNotes, clearCurrentToken, changeReloadCandleDataStatus, setSocket, setIsFromDeepLinkStatus, clearMyTransactionsError } =
  tokensSlice.actions;

export default tokensSlice.reducer;

export const myTokensSelector = (state: RootState) => state.tokens.myTokens;
export const myTokensPageSelector = (state: RootState) => state.tokens.myTokensPage;
export const myTransactionSelector = (state: RootState) => state.tokens.myTransaction;
export const communityNotesSelector = (state: RootState) => state.tokens.communityNotes;
export const lastNoteSelector = (state: RootState) => state.tokens.lastNote;
export const filteredTokensSelector = (state: RootState) => state.tokens.filteredTokens;
export const totalFilteredTokensSelector = (state: RootState) => state.tokens.totalFilteredTokens;
export const currentTokenSelector = (state: RootState) => state.tokens.currentToken?.token;
export const associatedAccountsSelector = (state: RootState) => state.tokens.currentToken?.associatedAccounts;
export const isPendingSelector = (state: RootState) => state.tokens.pending;
export const currentTokenPriceDataSelector = (state: RootState) => state.tokens.currentTokenPriceData;
export const currentCountTokensAndPriceSelector = (state: RootState) => state.tokens.currentCountTokensAndPrice;
export const currentCountTokensForSellSelector = (state: RootState) => state.tokens.currentCountTokensForSell;
export const reloadCandleDataSelector = (state: RootState) => state.tokens.isReloadCandleData;
export const socketConnectSelector = (state: RootState) => state.tokens.tokenSocket;
export const tokenProgressSelector = (state: RootState) => state.tokens.progress;
export const marcetCapSelector = (state: RootState) => state.tokens.marcetCap;
export const isCurrentTokenExistSelector = (state: RootState) => state.tokens.isCurrentTokenExist;
export const isFromDeepLinkSelector = (state: RootState) => state.tokens.isFromDeepLink;
export const transactionsErrorSelector = (state: RootState) => state.tokens.transactionsError;

