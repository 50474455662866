import { useSelector } from "react-redux";
import { GradientCard } from "../../../ui/GradientCard";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import { CopyToClipboardButton } from "../../../ui/CopyToClipboardButton";
import { shortenKey } from "../../../../helpers/shortenKey";

const TokenOverview = () => {
  const token = useSelector(currentTokenSelector);
  const { description, mintAddress } = token!;
  return (
    <div className="mt-4">
      <GradientCard backgroundColor="#28202C">
        <div className="px-4 pb-4 pt-3">
          <div className="pb-4 border-solid border-b-[2px] border-white/10">
            <p className="text-[12px] font-medium text-white/50">Meme Description</p>
            <p className="break-words">{description}</p>
          </div>
          <div className="pt-3 w-full leading-none">
            <p className="text-[12px] font-medium text-white/50">Adress</p>
            <div className="relative flex justify-between items-center  w-full">
              <div>
                <p className="pt-2 font-semibold text-skyBlue">{shortenKey(mintAddress || "token adress")}</p>
              </div>
              <CopyToClipboardButton value={mintAddress || "token adress"} />
            </div>
          </div>
        </div>
      </GradientCard>
    </div>
  );
};

export default TokenOverview;
