import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import {
  fetchIsCurrentTokenExist,
  fetchTokenInfoAction,
  fetchTokenProgress,
} from "../../store/token/actions/fetchTokenInfoAction";
import TokenHeader from "../../components/Pumpadur/Token/TokenTrade/TokenHeader";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import TokenChart from "../../components/Pumpadur/Token/TokenTrade/TokenChart";
import TradeActions from "../../components/Pumpadur/Token/TokenTrade/TradeActions";
import { useSelector } from "react-redux";
import {
  changeReloadCandleDataStatus,
  clearCurrentToken,
  isCurrentTokenExistSelector,
  isFromDeepLinkSelector,
  isPendingSelector,
  setIsFromDeepLinkStatus,
  setSocket,
} from "../../store/token/tokensReducer";
import TransactionSummary from "../../components/Pumpadur/Token/TokenTrade/Transaction/TransactionSummary";
import TransactionList from "../../components/Pumpadur/Token/TokenTrade/Transaction/TransactionList";
import LastNote from "../../components/Pumpadur/Notes/LastNote";
import { fetchLastNoteForTokenAction } from "../../store/token/actions/fetchLastNoteForTokenAction";
import scrollLock from "scroll-lock";
import { connectToSocket, disconnectSocket } from "../../helpers/connectSocket";
import { fetchCryptoProfileAction } from "../../store/cryptoProfile/actions/fetchCryptoProfileAction";
import { cryptoProfileBalanceSelector } from "../../store/cryptoProfile/cryptoProfileReducer";
import { Socket } from "socket.io-client";

const TokenTradePage = () => {
  const { tokenId } = useParams();

  const dispatch = useAppDispatch();
  const isFromDeepLink = useSelector(isFromDeepLinkSelector);
  const isPending = useSelector(isPendingSelector);
  const isTokenExist = useSelector(isCurrentTokenExistSelector);
  const phantomWalletSolanaBalance = useSelector(cryptoProfileBalanceSelector);

  useEffect(() => {
    let socket: Socket | null
    const setupSocket = async () => {
      socket = await connectToSocket();
      dispatch(setSocket(socket));
    };
    setupSocket();

    return () => {
      disconnectSocket(socket);
      dispatch(setSocket(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isFromDeepLink) return;

    const checkBalance = async () => {
      let attempts = 0;
      const maxAttempts = 5;

      while (attempts < maxAttempts) {
        try {
          const newSolBalance = await dispatch(fetchCryptoProfileAction()).unwrap();
          const newBalance = newSolBalance?.data.updatedCriptoProfile?.updatedCriptoProfile?.balance;

          if (newBalance === phantomWalletSolanaBalance) {
            attempts++;
            await new Promise((resolve) => setTimeout(resolve, 5000));
          } else {
            dispatch(setIsFromDeepLinkStatus(false));
            return;
          }
        } catch (error) {
          console.error("Error fetching crypto profile:", error);
          return;
        }
      }
      console.log("Balance check completed. No changes detected.");
    };

    checkBalance();
  }, [dispatch, isFromDeepLink]);

  // Загрузка начальных данных
  useEffect(() => {
    scrollLock.enablePageScroll();
    dispatch(changeReloadCandleDataStatus(true));
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchTokenInfoAction(tokenId!));
    dispatch(fetchIsCurrentTokenExist(tokenId!));
    dispatch(fetchTokenProgress(tokenId!));
    dispatch(fetchLastNoteForTokenAction(tokenId!));
    return () => {
      dispatch(changeStatusBottomMenu(true));
      dispatch(clearCurrentToken());
    };
  }, [tokenId]);
  
  

  // Обновление цены токена каждые 5 секунд
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchLastNoteForTokenAction(tokenId!));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [tokenId, dispatch]);

  return (
    <>
      {!isPending && isTokenExist && (
        <div className="pb-5">
          <TokenHeader />
          <TokenChart />
          <TradeActions />
          <TransactionSummary />
          <div className="pt-[4vh]">
            <LastNote />
          </div>
          <TransactionList />
        </div>
      )}
    </>
  );
};

export default TokenTradePage;
